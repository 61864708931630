<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap" v-if="canCreate">
            <v-btn
              class="ma-2"
              dark
              color="primary"
              @click="$router.push(baseRoute + 'create')"
            >
              <v-icon>mdi-plus</v-icon>
              Create New
            </v-btn>
          </div>
        </v-card-title>
        <v-card-title>
          <template v-if="page">
            {{ pageTitle }}
          </template>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <div class="d-flex">
            <v-select
              v-for="field in getModelFilterables"
              v-model="filters[field.id]"
              @change="search()"
              :key="field.id"
              :label="field.label"
              :items="field.options"
              item-text="name"
              item-value="id"
              class="mr-3"
              single-line
            ></v-select>
            <v-text-field
              v-model="query"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </div>
        </v-card-title>
        <v-data-table
          item-key="id"
          class="elevation-1 table-one"
          :footer-props="{ 'items-per-page-options': [100, 150, 300, -1] }"
          :headers="headers"
          :items="items.length ? items : []"
          :page="getModelsPage"
          :pageCount="getModelsPagesNumber"
          :options.sync="options"
          :server-items-length="total"
          :loading="getModelsLoading"
          :disable-sort="false"
          :disable-filtering="false"
        >
          <template
            v-for="av2ph in pdfHeaders"
            v-slot:[combine(av2ph)]="{ item }"
          >
            <a
              :key="av2ph"
              target="_blank"
              :href="`${storageURL}/${item[av2ph]}`"
              >View</a
            >
          </template>
          <template
            v-for="av2bh in booleanHeaders"
            v-slot:[combine(av2bh)]="{ item }"
          >
            <v-icon v-if="item[av2bh]" :key="av2bh">mdi-check</v-icon>
          </template>
          <template
            v-for="av2fh in imageableHeaders"
            v-slot:[combine(av2fh)]="{ item }"
          >
            <div :key="av2fh">
              <v-img
                class="previewable"
                v-if="isImage(item[av2fh])"
                @click="openModal(`${av2fh}${item[page.primary_key]}`)"
                :src="getThumbnail(item[av2fh])"
                max-height="40"
                max-width="40"
                :key="`${av2fh}${item[page.primary_key]}`"
                :alt="`${av2fh}${item[page.primary_key]}`"
              ></v-img>
              <Lightbox
                v-model="modal[`${av2fh}${item[page.primary_key]}`]"
                :src="getThumbnail(item[av2fh], true)"
                v-on:close="$forceUpdate()"
              ></Lightbox>
            </div>
          </template>
          <template v-slot:item.action="{ item }">
            <div class="d-flex" v-if="modifiable(item)">
              <v-tooltip top>
                <template v-if="canEdit" v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="
                      $router.push(baseRoute + item[page.primary_key] + '/edit')
                    "
                  >
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-if="canDelete" v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="danger"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="
                      confirmDelete({ model, id: item[page.primary_key] })
                    "
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </base-card>
      <v-dialog v-model="dialog" max-width="290">
        <v-card>
          <v-card-title class="text-h6"> Delete {{ model }} </v-card-title>
          <v-card-text>
            The {{ model }} will be deleted permanently. Are you sure?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn
              color="danger"
              text
              :loading="getModelsLoading"
              @click="deleteModel"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { MODEL_ACTION } from "@/shared/constants";
import { objToUrlParam, setListDataToModels } from "@/shared/utils";
import _ from "lodash";
import Lightbox from "@/components/Lightbox";

export default {
  metaInfo() {
    return {
      title:
        _.capitalize(_.upperFirst(_.replace(this.model, "-", " "))) || "Models"
    };
  },
  components: { Lightbox },
  data() {
    return {
      query: "",
      selected: null,
      dialog: false,
      modal: {},
      options: {},
      lightboxModal: false,
      filters: {}
    };
  },
  mounted() {
    this.setModelFilterables({ model: this.model });
  },
  created() {
    this.imageableHeaders.forEach(header => {
      _.map(this.items, item => {
        _.set(this.modal, `${header}${_.get(item, "id")}`, false);
      });
    });
  },
  methods: {
    ...mapActions(["setModels", "removeModel", "setModelFilterables"]),
    paginate() {
      this.setModels(this.paginationPayload);
    },
    search: _.debounce(function() {
      this.paginate();
    }, 500),
    combine(key) {
      return `item.${key}`;
    },
    confirmDelete(payload) {
      this.selected = payload;
      this.dialog = true;
    },
    async deleteModel() {
      await this.removeModel({
        model: this.model,
        id: _.get(this.selected, "id"),
        pagination: _.omit(this.paginationPayload, "model")
      });
      this.dialog = false;
    },
    modifiable(item) {
      return !_.get(item, "not_modifiable");
    },
    isImage(file) {
      return /\.(gif|jpe?g|png|webp|bmp)$/i.test(file);
    },
    getThumbnail(image, big = false) {
      const payload = objToUrlParam({
        resource: image,
        width: big ? "600" : "100",
        height: big ? "600" : "100",
        crop: Number(!big),
        force: 1
      });

      return `${process.env.VUE_APP_API_URL}/commands/thumbnail?${payload}`;
    },
    openModal(key) {
      this.$set(this.modal, key, true);
      this.$forceUpdate();
    }
  },
  watch: {
    getModelFilterables() {
      _.each(this.getModelFilterables, filter => {
        this.$set(this.filters, filter.id, 0);
      });
    },
    options: {
      handler() {
        this.paginate();
      }
    },
    query() {
      this.search();
    },
    deep: true
  },
  computed: {
    ...mapGetters([
      "getModels",
      "getModelsPage",
      "getModelsTotal",
      "getModelsPagesNumber",
      "getModelsLoading",
      "getModelFilterables"
    ]),
    items() {
      return setListDataToModels(
        _.cloneDeep(this.getModels),
        this.headers,
        this.model
      );
    },
    model() {
      return _.get(this.$route, "params.model");
    },
    page() {
      return this.$store.getters.getPage({ model: this.model });
    },
    headers() {
      const headers = this.$store.getters.getPageFieldsHeader(this.model);

      if (_.size(headers)) {
        headers.push({
          text: "Action",
          value: "action",
          sortable: false
        });
      }

      return headers;
    },
    booleanHeaders() {
      return _.map(
        _.filter(this.headers, header =>
          ["checkbox", "switch"].includes(header.type)
        ),
        header => _.get(header, "value")
      );
    },
    pdfHeaders() {
      return _.map(
        _.filter(this.headers, header => header.type === "pdf"),
        header => _.get(header, "value")
      );
    },
    imageableHeaders() {
      return _.map(
        _.filter(this.headers, header =>
          ["image", "file"].includes(header.type)
        ),
        header => _.get(header, "value")
      );
    },
    baseRoute() {
      return `/app/models/${this.model}/`;
    },
    canCreate() {
      return _.get(this.page, "actions", []).includes(MODEL_ACTION.CREATE);
    },
    canEdit() {
      return _.get(this.page, "actions", []).includes(MODEL_ACTION.EDIT);
    },
    canDelete() {
      return _.get(this.page, "actions", []).includes(MODEL_ACTION.DELETE);
    },
    pageTitle() {
      return _.get(this.page, "title", "");
    },
    paginationPayload() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      return {
        model: this.model,
        q: this.query,
        filters: _.pickBy(this.filters, _.identity),
        page,
        perPage: itemsPerPage,
        sortBy: _.first(sortBy),
        sortDesc: _.first(sortDesc)
      };
    },
    total() {
      return this.model === "domain"
        ? this.getModelsTotal || 1
        : this.getModelsTotal;
    },
    storageURL() {
      return `${process.env.VUE_APP_API_URL}/storage/file`;
    }
  },
  filters: {
    debug(value) {
      return JSON.stringify(value);
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }

      .previewable {
        cursor: pointer;
      }
    }
  }
}
</style>
